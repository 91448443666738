<template>
  <div>
    <v-row class="mx-0">
      <v-col cols="12" class="pa-0">
        <v-card class="rounded-b-xl" elevation="2">
          <v-list>
            <v-list-item v-for="document in documents" :key="document.id" @click="()=>{}" style="min-height:36px !important">
              <v-list-item-content class="py-0">
                <v-list-item-title class="font-weight-medium blue-grey--text darken-4" v-text="document.title ? document.title : document.original_name"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="pa-0 ma-0">
                <v-layout row wrap class="mx-0">
                  <v-btn small icon class="pa-0 ma-0" @click="downloadFile(document)">
                    <v-icon color="primary lighten-1">mdi-download</v-icon>
                  </v-btn>
                  <v-btn small icon class="pa-0 ma-0" @click="deleteFile(document)">
                    <v-icon color="error lighten-1">mdi-delete</v-icon>
                  </v-btn>
                </v-layout>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    documents: {
      type: Array,
      default: () => []
    },
    deleteIds: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      MODULE: 'uploaded_documents'
    }
  },
  created () {},
  mounted () {
    const text = 'message.common.deleteSuccess'
    this.$eventBus.$on('deleteSuccess', ({ document, activeModule }) => {
      if (activeModule === this.MODULE) {
        this.documents.splice(this.documents.indexOf(document), 1)
        this.deleteIds.push(document.id)
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
        this.$store.commit('hideDialog')
      }
    })
  },
  methods: {
    downloadFile (document) {
      if (document.downloadUrl) window.open(document.downloadUrl, '_blank')
      else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.urlNotFound.urlNotFound' })
    },
    deleteFile (document) {
      const payload = { isDocumentDelete: true, document, activeModule: this.MODULE } // it is local delete
      this.$store.commit('showDeleteDialog', payload)
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('deleteSuccess')
  }
}
</script>

<style>

</style>
